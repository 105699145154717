import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/HomeView.vue"),
  },
  {
    path: "/access/users",
    name: "users",
    component: () => import("@/views/access/UsersView.vue"),
  },
  {
    path: "/access/profiles",
    name: "profiles",
    component: () => import("@/views/access/ProfilesView.vue"),
  },
  {
    path: "/store/store",
    name: "stores",
    component: () => import("@/views/stores/StoresView.vue"),
  },
  {
    path: "/products/products",
    name: "products",
    component: () => import("@/views/products/ProductsView.vue"),
  },
  {
    path: "/products/discounts",
    name: "discounts",
    component: () => import("@/views/products/DiscountsView.vue"),
  },
  {
    path: "/products/prices",
    name: "prices",
    component: () => import("@/views/products/PricesView.vue"),
  },
  {
    path: "/reports/reports",
    name: "reports",
    component: () => import("@/views/reports/ReportsView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
