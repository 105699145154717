
import Vue from "vue";

export default Vue.extend({
  name: "App",
  components: {
    MainBar: () => import("./components/core/MainBar.vue"),
    MainDrawer: () => import("./components/core/MainDrawer.vue"),
    MainFooter: () => import("./components/core/MainFooter.vue"),
  },
  data: () => ({
    logged_in: false,
    message: "",
    version: "",
    customer_changed: false,
  }),
  methods: {
    customerChanged() {
      this.customer_changed = true;
    },
  },
  mounted() {
    if (this.$store.state.SessionModule.session) {
      this.message =
        this.$store.state.SessionModule.session.user.name + " is logged in";
    }
    if (document.querySelector("body")) {
      document.querySelector("body").style.overflow = "hidden";
    }
  },
});
