import modules from "./data/Modules.json";
import session from "./data/Session.json";

import faturamento_por_loja from "./data/FaturamentoLojas.json";
import produtos_por_regiao from "./data/ProdutosPorRegiao.json";
import vendas_por_regiao from "./data/VendasPorRegiao.json";
import products from "./data/Produtos.json";
import stores from "./data/Lojas.json";

const getRandomArbitrary = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min) + min);
};

const conversions: Array<number> = [];
for (let i = 0; i <= 5; i++) {
  conversions.push(getRandomArbitrary(400, 50));
}

const sales24: Array<number> = [];
for (let i = 0; i <= 5; i++) {
  sales24.push(getRandomArbitrary(1000.99, 700.99));
}

const access24: Array<number> = [];
for (let i = 0; i <= 5; i++) {
  access24.push(getRandomArbitrary(400, 2000));
}

export {
  modules,
  session,
  conversions,
  sales24,
  access24,
  faturamento_por_loja,
  produtos_por_regiao,
  vendas_por_regiao,
  products,
  stores,
  getRandomArbitrary,
};
