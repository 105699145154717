import Vue from "vue";
import Vuex from "vuex";
import { SessionModule } from "./modules/Session";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    SessionModule,
  },
  plugins: [createPersistedState()],
});
