import { Module } from "vuex";
import { NIL } from "uuid";
import { ELanguage } from "@/Interfaces/ILanguage";
import { session } from "@/Mockup/Data";

const baseUrl = "https://api.dev.arvis.digital";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SessionModule: Module<any, unknown> = {
  state: (): IState | boolean => {
    return {
      session: undefined,
      change_password: false,
    };
  },
  getters: {
    Session: (state: IState): ISession | boolean => {
      return state.session !== undefined ? state.session : false;
    },
    ChangePassword: (state: IState): boolean => {
      return state.change_password;
    },
  },
  mutations: {
    setSession: (state: IState, payload: ISession): void => {
      state.session = payload;
    },
    unsetSession: (state: IState): void => {
      state.session = undefined;
    },
    setChangePassword: (state: IState, payload: boolean): void => {
      state.change_password = payload;
    },
  },
  actions: {
    async login({ commit }, loginData: ILoginData): Promise<boolean> {
      try {
        commit("setSession", session);
        return true;
      } catch (error: any) {
        return error.message;
      }
    },
    async createSession({ commit, state }): Promise<string | boolean> {
      try {
        return true;
      } catch (error: any) {
        return error.message;
      }
    },

    async updateSession(
      { commit, state },
      { customer_id, user_id }
    ): Promise<string | boolean> {
      try {
        return true;
      } catch (error: any) {
        return error.message;
      }
    },

    async changePassword(
      { commit, state },
      passwordData: IPasswordData
    ): Promise<string | boolean> {
      try {
        return true;
      } catch (error: any) {
        return error.message;
      }
    },

    async logout({ commit }): Promise<string | boolean> {
      try {
        commit("unsetSession");
        return true;
      } catch (error: any) {
        return false;
      }
    },
    async forgotPassword(store, email: string): Promise<string | boolean> {
      try {
        return true;
      } catch (error: any) {
        return error.message;
      }
    },
    async saveNewPassword(
      store,
      { email, code, newPassword }
    ): Promise<string | boolean> {
      try {
        return true;
      } catch (error: any) {
        return error.message;
      }
    },
  },
};
//
export interface ILoginData {
  email: string;
  password: string;
  token: string;
}

export interface IAuthData {
  autorization_token: string;
}

export interface IPasswordData {
  password: string;
  newPassword: string;
  confirmNewPassword: string;
}

export enum EVerb {
  get = "GET",
  post = "POST",
  put = "PUT",
  delete = "DELETE",
}
export interface IRoute {
  verb: EVerb;
  url: string;
}

export interface IModule {
  name: string;
  description: string;
  routes: Array<IRoute>;
}

export interface IPermission {
  module: IModule;
  routes: Array<IRoute>;
}

export interface IProfile {
  name: string;
  permissions: Array<IPermission>;
}

export interface ISession {
  jwt: string;
  session_id: string;
  user: IUser;
  customers: Array<ICustomer>;
  user_profile?: Array<IProfile>;
  language: ELanguage;
  logged: boolean;
  error?: unknown;
  base_url?: string;
}

export interface IUser {
  id?: number;
  internal_id?: string;
  name?: string;
  customer_id?: number;
  customer_name?: string;
}

export interface ICustomer {
  current_selected: boolean;
  customer_id: number;
  customer_name: string;
  job_role: string;
  photo_url: string;
  user_first_name: string;
  user_id: number;
}

export interface IState {
  session?: ISession;
  change_password: boolean;
}

export { SessionModule };
