var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app',[(
      _vm.$store.state.SessionModule.session
        ? _vm.$store.state.SessionModule.session.logged
        : false
    )?_c('main-bar',{staticClass:"ma-0",on:{"customer_changed":function($event){return _vm.customerChanged()}}}):_vm._e(),(
      _vm.$store.state.SessionModule.session
        ? _vm.$store.state.SessionModule.session.logged
        : false
    )?_c('main-drawer',{staticClass:"mt-0",attrs:{"customer-changed":_vm.customer_changed}}):_vm._e(),_c('v-main',{staticClass:"ma-0 pa-0 pl-15"},[_c('router-view')],1),(
      _vm.$store.state.SessionModule.session
        ? _vm.$store.state.SessionModule.session.logged
        : false
    )?_c('main-footer',{attrs:{"message":_vm.message,"version":_vm.version}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }